<template>
  <b-container
    class="container-complete c-locator-item-complete center-vertical"
    fluid="flex"
  >
    <div class="container-lg">
      <div class="item-location-wp">
        <h1>{{ item.title }}</h1>
        <div class="btn-section">
          <a v-if="item.map_link" :href="item.map_link"
            ><img src="../../assets/images/map_marker.svg" />
            {{ $t("locator.map_label") }}</a
          >
          <a v-if="item.phone" :href="`tel:${item.phone}`"
            ><img src="../../assets/images/phone.svg" />{{
              $t("locator.labels.call")
            }}
          </a>
        </div>
        <div class="address loc-item-section">
          <div class="lbl">{{ $t("locator.labels.address") }}</div>
          <div class="address-info" v-html="item.address"></div>
        </div>
        <div class="phone loc-item-section" v-if="item.phone">
          <div class="lbl">{{ $t("locator.labels.phone") }}</div>
          <div class="phone-info">
            <span class="tel-item"
              ><a :href="`tel:${item.phone}`">{{ item.phone }}</a></span
            >
            <span class="tel-item" v-if="item.fax_number">
              <a :href="`tel:${item.fax_number}`">{{
              item.fax_number
            }}</a></span>
          </div>
        </div>
        <div
          class="services loc-item-section"
          v-if="Array.isArray(item.services)"
        >
          <div class="lbl">{{ $t("locator.labels.services") }}</div>
          <div class="services-info">
            <span
              class="service-item"
              v-for="service in item.services"
              :key="service.drupal_internal__tid"
              >{{ service.name }}</span
            >
          </div>
        </div>
        <!--                <div class="services loc-item-section">-->
        <!--                    <div class="lbl">Services</div>-->
        <!--                    <div class="services-info">-->
        <!--                        <span class="service-item">Commercial Banking</span>-->
        <!--                        <span class="service-item">Drive Thru</span>-->
        <!--                        <span class="service-item">Drive Thru ATM</span>-->
        <!--                        <span class="service-item">FirstMortgage</span>-->
        <!--                        <span class="service-item">FirstInsurance</span>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="hourly loc-item-section" v-if="item.monday_friday">
          <div class="lbl">{{ $t("locator.labels.hourly") }}</div>
          <div class="services-info">
            <div class="hourly-item">
              <span class="lbl">{{ $t("locator.week.monday") }}: </span>
              <span class="hourly-item-data">{{ item.monday_friday }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_autobank }}</span>
            </div>
            <div class="hourly-item">
              <span class="lbl">{{ $t("locator.week.tuesday") }}: </span>
              <span class="hourly-item-data">{{ item.monday_friday }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_autobank }}</span>
            </div>
            <div class="hourly-item">
              <span class="lbl">{{ $t("locator.week.wednesday") }}: </span>
              <span class="hourly-item-data">{{ item.monday_friday }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_autobank }}</span>
            </div>
            <div class="hourly-item">
              <span class="lbl">{{ $t("locator.week.thursday") }}: </span>
              <span class="hourly-item-data">{{ item.monday_friday }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_autobank }}</span>
            </div>
            <div class="hourly-item">
              <span class="lbl">{{ $t("locator.week.friday") }}: </span>
              <span class="hourly-item-data" v-if="item.monday_friday_extended">{{ item.monday_friday_extended }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_extended_autobank ? item.monday_friday_extended_autobank : item.monday_friday_autobank }}</span>
              <span class="hourly-item-data" v-else>{{ item.monday_friday }} <span v-if="item.monday_friday_autobank">-</span> <span style="color: #303030" v-if="item.monday_friday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.monday_friday_autobank }}</span>
            </div>
            <div class="hourly-item" v-if="item.saturday || item.saturday_autobank">
              <span class="lbl">{{ $t("locator.week.saturday") }}: </span>
              <span class="hourly-item-data">{{ item.saturday }} <span v-if="item.saturday && item.saturday_autobank">-</span> <span style="color: #303030" v-if="item.saturday_autobank">{{ $t('locator.week.autobank') }}</span> {{ item.saturday_autobank }}</span>
            </div>
            <div class="hourly-item" v-if="item.sunday">
              <span class="lbl">{{ $t("locator.week.sunday") }}: </span>
              <span class="hourly-item-data">{{ item.sunday }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "LocatorItemComplete",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    openLocation(id) {
      const newId = Math.floor(Math.random() * (10 - 1 + 1)) + id;
      const newHash = "#location-" + newId;
      if (this.$router.currentRoute.hash !== newHash) {
        this.$router.push({ name: "Home", hash: newHash });
      }
    }
  }
};
</script>

<style scoped></style>
