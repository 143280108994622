import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/i18n";
import AxiosAPI from "./services/axios";
import contentAPI from "./api/contentApi";
import interceptors from "@/utils/interceptors";
import VueGtag from "vue-gtag";
import store from "./store";
import helperList from "@/utils/helpers";
import VueGeolocation from "vue-browser-geolocation";
import VModal from "vue-js-modal";
import vue_cookies from "vue-cookies";


import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(vue_cookies);
import "./assets/scss/main.scss";

Vue.use(VueGeolocation);

Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
    scrollable: true
  }
});

Vue.use(
  VueGtag,
  {
    config: { id: "GTM-T56FS6L" },
    appName: "Locator FirstBank",
    pageTrackerScreenviewEnabled: true
  },
  router
);

Vue.config.productionTip = false;
Vue.prototype.$contentApi = contentAPI;
Vue.prototype.$http = AxiosAPI;
Vue.prototype.$store = store;
Vue.prototype.$helperList = helperList;
interceptors();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

let app;
if (!app) {
  app = new Vue({
    el: "#app",
    store,
    i18n,
    router,
    contentAPI,
    created() {},
    render: h => h(App)
  });
}