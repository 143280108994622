<template>
    <div class="fsb-social" v-if="block.body" v-html="block.body.value">
    </div>
</template>

<script>
    export default {
        name: "socialFooterBlock",
        props: {
            block: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>