<template>
  <b-container
    class="container-3 c-locator-filter center-vertical"
    fluid="flex"
  >
    <div class="container-lg">
      <div class="header-filter">
        <h2>{{ $t("locator.filters.filter") }}</h2>
        <a href="#" class="all all-link"  @click="activeFilter('all', $event)">{{
          $t("locator.filters.all_results")
        }}</a>
      </div>

      <div class="filter-links">
        <a
          href="#"
          @click="activeFilter('all', $event)"
          class="filter-link all"
          :class="[filters.all ? 'active' : '']"
          >{{ $t("locator.filters.all") }}</a
        >
        <a
          href="#"
          @click="activeFilter('branches', $event)"
          class="filter-link branch"
          :class="[filters.branches ? 'active' : '']"
          >{{ $t("locator.filters.branches") }}</a
        >
        <a
          href="#"
          @click="activeFilter('atm', $event)"
          class="filter-link atm"
          :class="[filters.atm ? 'active' : '']"
          >{{ $t("locator.filters.atms") }}</a
        >
        <a
          href="#"
          @click="activeFilter('depositExpress', $event)"
          class="filter-link deposit"
          :class="[filters.depositExpress ? 'active' : '']"
          >{{ $t("locator.filters.deposit") }}</a
        >
        <a
            href="#"
            @click="activeFilter('firstDeposit', $event)"
            class="filter-link first-deposit"
            :class="[filters.firstDeposit ? 'active' : '']"
        >{{ $t("locator.filters.firstDeposit") }}</a
        >
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "LocatorFilter",
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newFilters: null
    };
  },
  methods: {
    activeFilter(currentKey, event) {
      event.preventDefault();
      let filters = this.filters;
      Object.keys(filters).forEach(key => {
        if (key === currentKey) {
          filters[key] = 1;
        } else {
          filters[key] = 0;
        }
      });
      this.filters = filters;
      this.$emit("set-filters", filters);
    }
  }
};
</script>

<style scoped></style>
