<template>
    <b-container class="container-6 c-locator-items center-vertical" fluid="flex">
        <div class="container-lg">
            <div class="location-item-container"
            v-for="(item, index) in items"
            :key="index"
            >
                <locator-item :item="item" />
            </div>
        </div>
    </b-container>
</template>

<script>
    import LocatorItem from "./Item";
    export default {
        name: "LocatorItems",
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        components: {LocatorItem},
    }
</script>

<style scoped>

</style>