<template>
    <div class="site--navigation px17">
        <b-navbar-nav class="float-left navbar-right mr-auto main-menu nav page__navigation navigation">
            <li
                    v-for="item in menu"
                    :key="item.id"
            >
<!--                :class="[helpers.haveSubMenu(item.field_pg_menu_link_content, item.field_pg_menu_link_sub) ? 'has-submenu' : '']"-->
                <a :href="helpers.getMenuUrl(item.field_pg_menu_link.uri, language)" v-if="helpers.getMenuUrl(item.field_pg_menu_link.uri, language)">{{ item.field_pg_menu_link.title }}</a>
                <span v-else v-html="helpers.renderSpanString(item.field_pg_menu_link.title)"></span>
<!--                <ul class="sub-menu"-->
<!--                    v-if="helpers.haveSubMenu(item.field_pg_menu_link_content, [])"-->
<!--                >-->
<!--                    <li v-html="item.field_pg_menu_link_content.value"></li>-->
<!--                </ul>-->
            </li>
        </b-navbar-nav>
    </div>
</template>

<script>
    export default {
        name: "MainMenu",
        props: {
            menu: {
                type: Array,
                required: true
            }
        },
        data(){
            return{
                helpers: this.$helperList,
                language: this.$i18n.locale,
            };
        },
        methods: {},
    }
</script>

<style lang="scss" scoped>
    li:hover{
        .sub-menu{
            @media(min-width: 1025px){
                padding-top: 120px;
            }
        }
        > li{
         a, span{
             line-height: 1;
         }
        }
    }

</style>