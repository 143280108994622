<template>
    <div class="pnumbers-wrapper">
        <div class="pnumbers ccol-lg-6">
                        <span class="p_icon">
                          <img src="../../assets/images/phone.svg" alt="icon">
                        </span>
                        <span class="body-c-sec" v-html="block.body.value"></span>
        </div>
        <div class="pnumbers ccol-lg-6">
            <span class="p_icon">
                <img src="../../assets/images/routing.svg" alt="icon">
            </span>
            <span v-html="block.field_header_title.value" class="body-c-sec"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "phonesFooterBlock",
        props: {
            block: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>