import $http from "@/services/axios";
import { API_ENDPOINTS } from "../utils/apiCollection";

async function getLocations(radio, lat, lng, paramsRoute) {
    let params = {
        jsonapi_include: 1
    };

    if (radio && lat && lng) {
        params = {
            jsonapi_include: 1,
            "fgp[value]": radio,
            "fgp[source_configuration][origin][lat]": lat,
            "fgp[source_configuration][origin][lon]": lng,
            "region": paramsRoute.region,
        };
    }
    // console.log("PARAMS ON SEARCH VIEW", params);
    return await $http
        .get(`/${paramsRoute.lang}${API_ENDPOINTS.GEO_LOCATION_URI}`, {
            params
        })
        .then(({ data }) => {
            return data;
        });
}

export { getLocations };
