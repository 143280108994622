import Vue from "vue";
import Vuex from "vuex";
import locations from "./modules/locations";
import menus from "./modules/menus";
import blocks from "./modules/blocks";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['locations.prEs','locations.prEn', 'menus.prEs', 'menus.prEn', 'blocks.prEn', 'blocks.prEs']
})

const store = new Vuex.Store({
  modules: {
    locations,
    menus,
    blocks,
  },
  plugins: [dataState],
});

export default store;