<template>
  <main role="main" class="main-container-c-wp">
    <cookie-header />
    <div class="main-container-c container-xl">
      <div v-if="validateCookies()" class="pl-0 pr-0 container-lg">
        <div class="header-content container-lg">
          <section class="section-item section-1">
            <h2>{{ printLanguage(general.title) }}</h2>
            <div class="section-content">
              <p>{{ printLanguage(general.p) }}</p>
              <p>{{ printLanguage(general.p1) }}</p>
              <p>{{ printLanguage(general.p2) }}</p>
            </div>
          </section>
          <section
            v-for="(item, index) in cookies"
            :key="item.id"
            class="section-item section-sub"
            :class="[
              index === 0 ? 'first' : '',
              index + 1 === cookies.length ? 'last' : '',
              `section-item-${item.id}`
            ]"
          >
            <h3 v-html="printLanguage(item.title)" />
            <div
              class="section-content"
              v-html="printLanguage(item.description)"
            />
            <div class="check-select">
              <div
                class="switch-on-off-wrapper"
                :class="[
                  item.name,
                  item.value ? 'switch-on-wrapper' : 'switch-off-wrapper'
                ]"
                @click.prevent="assignCookieValue(item.value, index)"
              >
                <div
                  class="switch-circle"
                  :class="[item.name, item.value ? 'switch-on' : 'switch-off']"
                >
                  <p v-if="item.value" class="switch-on-off-text">
                    {{ printLanguage(yes) }}
                  </p>
                  <p v-if="!item.value" class="switch-on-off-text">No</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CookieHeader from "../components/cookieHeader";
export default {
  name: "Cookies",
  components: { CookieHeader },
  title() {
    return `FirstBank — Cookies`;
  },
  data() {
    return {
      general: {
        title: {
          es: "1. Cookies y anuncios",
          en: "1. Cookies and Ads"
        },
        p: {
          es:
            "Cuando visita cualquier sitio web, el mismo podría obtener o guardar información en su navegador, generalmente mediante el uso de cookies para recoger información sobre sus actividades en línea. Esta información puede ser acerca de usted, sus preferencias o su dispositivo, y se usa principalmente para que el sitio web funcione según lo esperado. Por lo general, la información no lo identifica directamente, pero puede proporcionarle una experiencia web más personalizada. También se utilizan cookies para otros propósitos, tales como para mantener continuidad durante una sesión en línea, evitar fraude y para propósitos de seguridad de información.",
          en:
            "When you visit any website, it may obtain or save information in your browser, generally through the use of cookies to collect information about your online activities. This information may be about you, your preferences, or your device, and is primarily used to make the website work as expected. The information does not generally identify you directly, but it can provide you with a more personalized web experience. Cookies are also used for other purposes, such as to maintain continuity during an online session, prevent fraud, and for information security purposes."
        },
        p1: {
          es:
            "Podríamos usar tecnologías similares, como balizas web (web beacons), accesos a contenidos, registros de entrada (logs) y etiquetas (tags) de por sí o en conjunto con cookies, para recoger estadísticas sobre sus patrones de navegación y/o para evaluar su actividad en general en nuestro sitio web, incluyendo con qué frecuencia lo visita. Se puede colocar balizas web en algún correo electrónico que le envíen, para determinar si oprimió un enlace en ese email que le dirigió a nuestro sitio web. A fin de desplegar esta tecnología y recoger la mencionada información, trabajamos con terceros para rastrear el uso en los sitios web visitados y mensajes de email. Estos terceros nos proveen información sobre sus actividades en línea. Estas tecnologías nos permiten proveer contenido y/o promociones, como correos electrónicos, con ofertas que se ajusten a sus intereses. Esta práctica es conocida como publicidad de acuerdo con sus intereses en línea (online behavioral advertising).",
          en:
            "We could use similar technologies, such as web beacons, access to content, input records (logs) and tags (tags) by themselves or in conjunction with cookies, to collect statistics on your browsing patterns and / or to evaluate your general activity on our website, including how often you visit it. Web beacons may be placed in an email sent to you to determine if you clicked on a link in that email that directed you to our website. In order to deploy this technology and collect such information, we work with third parties to track usage on visited websites and email messages. These third parties provide us with information about your online activities. These technologies allow us to provide content and / or promotions, such as emails, with offers that are tailored to your interests. This practice is known as advertising according to your interests online (online behavioral advertising)."
        },
        p2: {
          es:
            "Puede controlar el uso de cookies. Abajo encontrará las diferentes categorías para saber más y cambiar nuestras configuraciones predeterminadas. Sin embargo, es importante que esté al tanto de que el bloqueo de algunos tipos de cookies puede afectar su experiencia en el sitio web y los servicios que podemos ofrecer.",
          en:
            "You can control the use of cookies. Below you will find the different categories to learn more and change our default settings. However, it is important that you are aware that blocking some types of cookies may affect your experience on the website and the services we can offer."
        }
      },
      yes: {
        es: "Si",
        en: "Yes"
      },
      language: this.$i18n.locale,
      title: {
        es: "Personaliza los cookies",
        en: "Customize cookies"
      },
      cookies: {
        required: {
          id: 1,
          title: {
            es: "Cookies estrictamente necesarias",
            en: "Strictly necessary cookies"
          },
          description: {
            es:
              "Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Usualmente están configuradas para manejar y poder ofrecerle los servicios que nos solicita, tales como ajustar sus preferencias de privacidad, iniciar sesión en el sitio web, o llenar formularios. Puede configurar su navegador para bloquear o alertar la presencia de estas cookies, pero algunas partes del sitio web no funcionarán adecuadamente.",
            en:
              "These cookies are necessary for the website to function and cannot be disabled in our systems. They are usually configured to manage and be able to offer you the services that you request, such as adjusting your privacy preferences, logging in to the website, or filling out forms. You can configure your browser to block or alert the presence of these cookies, but some parts of the website will not work properly."
          },
          value: true,
          name: "required-cbx"
        },
        analytics: {
          id: 2,
          title: {
            es: "Cookies de rendimiento",
            en: "Analytics Cookies"
          },
          description: {
            es:
              "Estas cookies nos permiten contar las visitas y las fuentes de tráfico, para que podamos medir y mejorar el rendimiento de nuestro sitio. Nos ayudan a comprender cómo los visitantes interactúan con nuestros sitios web al recopilar y reportar información de forma anónima.",
            en:
              "These cookies allow us to count visits and traffic sources, so that we can measure and improve the performance of our site. They help us understand how visitors interact with our websites by collecting and reporting information anonymously."
          },
          value: true,
          name: "analytics"
        }
      },
      cookiesValues: {
        required: {
          id: 1,
          value: true
        },
        analytics: {
          id: 2,
          value: true
        }
      }
    };
  },
  methods: {
    printLanguage($data) {
      if (this.language === "en") {
        return $data.en;
      }

      return $data.es;
    },
    validateCookies() {
      const cookiesStatus = this.$cookies.get("fb_location_c_status");
      const localThis = this;

      if (cookiesStatus) {
        Object.keys(cookiesStatus).map(function(key) {
          if(key in localThis.cookies && key in localThis.cookiesValues){
            localThis.cookies[key].value = cookiesStatus[key].value;
            localThis.cookiesValues[key].value = cookiesStatus[key].value;
          }
        });
      } else {
        this.$cookies.set("fb_location_c_status", this.cookiesValues, {
          maxAge: 60 * 60 * 24 * 365
        });
      }

      return true;
    },
    assignCookieValue(value, id) {
      this.cookies[id].value = !value;
      this.cookiesValues[id].value = !value;
      if (id === "required") {
        this.cookies[id].value = true;
        this.cookiesValues[id].value = true;
      }
      this.$cookies.set("fb_location_c_status", this.cookiesValues, {
        maxAge: 60 * 60 * 24 * 365
      });
    }
  },
  watch: {
    $route() {
      const oldLanguage = this.language;
      this.language = this.$i18n.locale;
      if (oldLanguage !== this.language) {
        if (this.language === "en") {
          const pathEN = "en/cookies";
          // window.location.href= pathEN;
          this.$router.push(pathEN);
        } else {
          const pathES = "es/cookies";
          // window.location.href= pathES;
          this.$router.push(pathES);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-container-c-wp {
  background: #ffffff;
  padding-bottom: 100px;
  /*margin-top: 6.7rem;*/
  /*@media(max-width: 1024px){*/
  /*  margin-top: 4.2rem;*/
  /*}*/
  /*@media(max-width: 770px){*/
  /*  margin-top: 4.1rem;*/
  /*}*/
}
.main-container-c {
  /*max-width: 1440px;*/
  margin-left: auto;
  margin-right: auto;
  /*padding-left: 60px;*/
  /*padding-right: 60px;*/
  @media (max-width: 1024px) {
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }
}
h3,
h2 {
  color: #038459;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
  @media (max-width: 750px) {
    font-size: 24px;
  }
}
h2 {
}
h3 {
  font-size: 18px;
  margin-bottom: 0.5rem;
  @media (max-width: 1024px) {
    margin-top: 1rem;
  }
  @media (max-width: 750px) {
    font-size: 16px;
  }
}
.section-content,
p {
  font-size: 15px;
  color: #000;
  @media (max-width: 750px) {
    font-size: 14px;
  }
}
.switch-on-off-wrapper {
  width: 100px;
  height: 40px;
  border-radius: 25px;
  box-shadow: inset 0 0 4px #696969;
  cursor: pointer;
  &.switch-off-wrapper {
    background-color: #cccccc;
    /*animation-name: switch-off-wrapper-animation;*/
    /*animation-duration: 1s;*/
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
  }
  &.switch-on-wrapper {
    background-color: #038459;
    /*animation-name: switch-on-wrapper-animation;*/
    /*animation-duration: 1s;*/
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
  }
  .switch-circle {
    background-color: white;
    width: 50px;
    height: 30px;
    display: inline-block;
    border-radius: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    top: 5px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  .switch-on {
    -webkit-animation: slide-on 0.5s forwards;
    animation: slide-on 0.5s forwards;
    left: 45px;
    /*animation-name: switch-on-animation;*/
    /*animation-duration: 1s;*/
  }
  .switch-off {
    -webkit-animation: slide-off 0.5s forwards;
    animation: slide-off 0.5s forwards;
    left: 5px;
    /*animation-name: switch-off-animation;*/
    /*animation-duration: 1s;*/
  }
  .switch-on-off-text {
    color: #038459;
    margin: 10px auto;
    line-height: 1;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  @-webkit-keyframes slide-off {
    from {
      left: 45px;
    }
    to {
      left: 5px;
    }
  }

  @keyframes slide-on {
    from {
      left: 5px;
    }
    to {
      left: 45px;
    }
  }
}
.check-select {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
</style>
