<template>
  <b-container
    class="container-c-6 c-locator-item center-vertical"
    fluid="flex"
  >
    <div class="container-lg-sub">
      <div class="item-location-wp">
        <a href="#" class="loc-title" @click.prevent="openLocation(item.id)"
          ><span v-html="item.title"></span></a
        >
        <div class="address loc-item-section">
          <div class="lbl">{{ $t("locator.labels.address") }}</div>
          <div class="address-info" v-html="item.address"></div>
        </div>
        <div class="phone loc-item-section" v-if="item.phone">
          <div class="lbl">{{ $t("locator.labels.phone") }}</div>
          <div class="phone-info">
            <span class="tel-item" v-if="item.phone"
              ><a :href="`tel:${item.phone}`">{{ item.phone }}</a></span
            >
            <span class="tel-item" v-if="item.fax_number"
              ><a :href="`tel:${item.fax_number}`">{{
                item.fax_number
              }}</a></span
            >
          </div>
        </div>
        <div
          class="services loc-item-section"
          v-if="Array.isArray(item.services)"
        >
          <div class="lbl">{{ $t("locator.labels.services") }}</div>
          <div class="services-info">
            <span
              class="service-item"
              v-for="service in item.services"
              :key="service.drupal_internal__tid"
              >{{ service.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "LocatorItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    openLocation(id) {
      const newHash = "#location-" + id;
      const name = this.$route.name;
      if (this.$router.currentRoute.hash !== newHash) {
        this.$router.push({ name: name, hash: newHash });
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style scoped lang="scss">
/*.loc-title{*/
/*    display: flex;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*}*/
/*.loc-title .text{*/
/*    float: left;*/
/*    &:after{*/
/*        content: '>';*/
/*        display: inline-block;*/
/*        float: right;*/
/*        width: 10px;*/
/*        height: 10px;*/
/*    }*/

/*}*/
</style>
