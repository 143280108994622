<template>
  <div
    class="lang-section lang-menu"
    :class="[open ? 'section-active' : '']"
    v-if="setDefault()"
  >
    <!--    v-show="setDefault()"-->
    <b-navbar-nav class="float-left navbar-right mr-auto">
      <li class="sign-has-menu">
        <span class="btn" @click="openModal">
          <span class="region">{{ labels.region }}</span>
          <span class="lang">{{ labels.lang }}</span>
        </span>
        <ul class="sub-menu" :class="[open ? 'active' : '']">
          <div class="desktop__options">
            <div class="n-row">
              <div class="region_radio ccol-md-4 lang-col">
                <div class="column__top">
                  <h2 class="title">{{ $t("lang.region") }}</h2>
                </div>

                <ul
                  role="radiogroup"
                  aria-label="region_radio"
                  class="radio--list2 list--style1"
                >
                  <li>
                    <div
                      class="input--holder2"
                      :class="[region.pr ? 'checked' : '']"
                    >
                      <label>
                        <input
                          class="region_pr_check"
                          name="region"
                          type="radio"
                          v-model="region.pr"
                          :value="region.pr"
                          @click="updateRegion('pr')"
                        />
                        <label
                          class="region_label region_pr_label"
                          style="background-position:26px 0;"
                        >
                          Puerto Rico</label
                        >
                      </label>
                    </div>
                  </li>
                  <li>
                    <div
                      class="input--holder2"
                      :class="[region.fl ? 'checked' : '']"
                    >
                      <label
                        ><input
                          class="region_fl_check"
                          name="region"
                          type="radio"
                          :value="region.fl"
                          v-model="region.fl"
                          @click="updateRegion('fl')"
                        />
                        <label
                          class="region_label region_fl_label"
                          style="background-position:26px 0;"
                        >
                          Florida - USA</label
                        ></label
                      >
                    </div>
                  </li>
                  <li>
                    <div
                      class="input--holder2"
                      :class="[region.vi ? 'checked' : '']"
                    >
                      <label
                        ><input
                          class="region_vi_check"
                          name="region"
                          type="radio"
                          :v-model="region.vi"
                          :value="region.vi"
                          @click="updateRegion('vi')"
                        />
                        <label class="region_vi_label region_label">
                          Islas Vírgenes - Virgin Islands</label
                        ></label
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="ccol-md-4 lang-col">
                <div class="lang_region drop__col small--col">
                  <div class="column__top">
                    <h2 class="title">{{ $t("lang.lang") }}</h2>
                  </div>
                  <ul
                    role="radiogroup"
                    aria-label="lang_radio"
                    class="radio--list2 list--style1"
                  >
                    <li v-show="region.pr">
                      <div
                        class="input--holder2"
                        :class="lang.es ? 'checked' : ''"
                      >
                        <input
                          class="lang_es_check"
                          name="lang"
                          type="radio"
                          value="es"
                          @click="updateLang('es')"
                          aria-label="Español"
                        />
                        <label class="lang_label lang_es_label">
                          Español</label
                        >
                      </div>
                    </li>
                    <li>
                      <div
                        class="input--holder2"
                        :class="lang.en ? 'checked' : ''"
                      >
                        <label
                          ><input
                            class="lang_en_check"
                            name="lang"
                            type="radio"
                            value="en"
                            @click="updateLang('en')"
                          />
                          <label
                            class="lang_label lang_en_label"
                            style="background-position:26px 0;"
                          >
                            English</label
                          ></label
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </li>
    </b-navbar-nav>
  </div>
</template>

<script>
export default {
  name: "languageSelector",
  data() {
    return {
      open: false,
      helpers: this.$helperList,
      defaults: false,
      region: {
        pr: true,
        vi: false,
        fl: false
      },
      lang: {
        es: true,
        en: false
      },
      pages: {
        pr: {
          es: "HomePrEs",
          en: "HomePrEn"
        },
        fl: {
          en: "HomeFlEn"
        },
        vi: {
          en: "HomeViEn"
        }
      },
      labels: {
        region: "PR",
        lang: "Español"
      }
    };
  },
  watch: {
    $route() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      this.updateLabels(params.region, params.lang);
    }
  },
  methods: {
    checkCurrentLang() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      this.updateLabels(params.region, params.lang);
      return true;
    },
    updateLabels(regionData, langData) {
      const region = regionData.toLowerCase();
      const lang = langData.toLowerCase();
      const labels = this.labels;
      labels.lang = "English";
      labels.lang = "English";
      if (region === "pr") {
        labels.region = "PR";
        if (lang === "es") {
          labels.lang = "Español";
        }
      }
      if (region === "fl") {
        labels.region = "FL";
      }
      if (region === "vi") {
        labels.region = "VI";
      }

      this.labels = labels;
    },
    setDefault() {
      if (this.defaults) {
        return true;
      }
      const params = this.helpers.getRouteParameters(this.$route.path);
      if (params) {
        const region = params.region.toLowerCase();
        const lang = params.lang;
        this.resetRegion();
        this.resetLang();
        this.lang[lang] = true;
        this.region[region] = true;
        this.defaults = true;
        // this.updateLabels(region, lang);
        // this.updateRegion(region);
        // this.updateLang(lang);
      }
      return true;
    },
    openModal() {
      this.open = !this.open;
    },
    resetRegion() {
      const region = this.region;
      Object.keys(region).forEach(function(key) {
        region[key] = false;
      });
      this.region = region;
    },
    resetLang() {
      const lang = this.lang;
      Object.keys(lang).forEach(function(key) {
        lang[key] = false;
      });
      this.lang = lang;
    },
    updateRegion(id) {
      const region = this.region;
      Object.keys(region).forEach(function(key) {
        region[key] = false;
      });
      region[id] = true;
      this.region = region;
      if (id === "fl" || id === "vi") {
        this.lang.es = false;
        this.lang.en = true;
        this.open = false;
        // this.updateLabels(id, 'en');
        this.updatePage(id, "en");
      }
      if (id === "pr") {
        this.lang.es = false;
        this.lang.en = false;
      }
    },
    updatePage(region, language) {
      const currentName = this.$route.name;
      const name = this.pages[region][language];
      const hash = this.$route.hash;
      // this.updateLabels(region, language);
      if (currentName === name) {
        return true;
      }
      if (name) {
        window.location = "/" + region + "/" + language + hash;
        // this.$router.push({name: name, hash: hash})
      }
    },
    updateLang(id) {
      this.resetLang();
      this.lang[id] = true;
      this.updatePage("pr", id);
      this.open = false;
    }
  }
};
</script>

<style scoped></style>
