import { API_ENDPOINTS } from "../utils/apiCollection";
import $http from "@/services/axios";

// import { SUPPORTED_LANGUAGES } from "@/utils/i18Defaults";
async function getLocationPR(lang) {
  let params = {
    jsonapi_include: 1
  };

  return await $http
    .get(`/${lang}${API_ENDPOINTS.GET_LOCATIONS_PR}`, {
      params
    })
    .then(({ data }) => {
      return data;
    });
}

async function getLocationList(lang, region, offset) {
  const contentURI = `/${lang}${API_ENDPOINTS.GET_LOCATIONS}`;

  const params = {
    jsonapi_include: "1",
    "filter[field_region]": region,
    "filter[fw][condition][path]": "field_website.drupal_internal__tid",
    "filter[fw][condition][value]": 22,
    "page[offset]": offset,
    "page[limit]": 50,
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getMenu(lang, region) {
  const template = API_ENDPOINTS.MENU_TYPE + "-" + region.toLowerCase();
  const contentURI = `/${lang}${API_ENDPOINTS.GET_MENU}`;
  let params = {
    jsonapi_include: "1",
    "filter[menu_type]": template,
    "filter[field_gid]": 23
  };
  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getBlock(lang, region) {
  const template = API_ENDPOINTS.BLOCK_TYPE + "-" + region.toLowerCase();
  const contentURI = `/${lang}${API_ENDPOINTS.GET_BLOCK}`;
  let params = {
    jsonapi_include: "1",
    "filter[field_template]": template,
    "filter[field_gid]": 23
  };
  return await $http.get(`${contentURI}`, {
    params
  });
}

export { getLocationPR, getLocationList, getMenu, getBlock };
