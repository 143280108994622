<template>
  <div class="container-wp">
    <locator-header />
    <locator-search
      @search-items="searchItems"
      @position="searchPosition"
      :reset="searchReset"
    />
    <div class="location-g-wrapper" v-if="locationsCurrent">
      <div class="locator-list" v-if="!hash">
        <locator-filter :filters="filters" @set-filters="setFilters" />
        <locator-counter :counter="locationsCurrent.length" />
        <locator-items :items="locationsCurrent" />
      </div>
      <div class="locator-item" v-else>
        <locator-item-complete-back />
        <locator-item-complete
          v-if="currentItemExist(hash)"
          :item="locationsCurrentItem"
        />
      </div>
    </div>
    <speed-bump v-if="locationsCurrent" />
  </div>
</template>
<script>
import LocatorHeader from "../components/Locator/Header";
import LocatorSearch from "../components/Locator/Search";
import LocatorFilter from "../components/Locator/Filter";
import LocatorCounter from "../components/Locator/Counter";
import LocatorItems from "../components/Locator/Items";
import LocatorItemCompleteBack from "../components/Locator/itemCompleteBack";
import LocatorItemComplete from "../components/Locator/ItemComplete";
import { getLocations } from "@/api/geoLocations";
import { mapGetters } from "vuex";
import SpeedBump from "../components/sections/speedBump";
export default {
  name: "Home",
  data() {
    return {
      searchByPosition: false,
      position: null,
      language: "es",
      helpers: this.$helperList,
      hash: false,
      locationsSearchText: null,
      locationsBase: null,
      locationsData: null,
      locationsCurrent: null,
      locationsCurrentByPosition: null,
      locationsCurrentTemp: null,
      locationsCurrentItem: null,
      searchReset: false,
      filtersActive: false,
      filters: {
        all: 1,
        branches: 0,
        atm: 0,
        depositExpress: 0,
        firstDeposit: 0
      }
    };
  },
  components: {
    SpeedBump,
    LocatorItemComplete,
    LocatorItemCompleteBack,
    LocatorItems,
    LocatorCounter,
    LocatorFilter,
    LocatorSearch,
    LocatorHeader
  },
  async created() {
    this.resetStates();
    await this.setLocations();
  },
  computed: {
    ...mapGetters([
      "locations/getLocations",
      "locations/getLocationsCount"
    ])
  },
  methods: {
    cleanAccent(text) {
      const accent_map = {
        á: "a",
        é: "e",
        è: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "a",
        É: "e",
        Í: "i",
        Ó: "o",
        Ú: "u"
      };
      if (!text) {
        return "";
      }
      let ret = "";
      for (let i = 0; i < text.length; i++) {
        ret += accent_map[text.charAt(i)] || text.charAt(i);
      }
      return ret;
    },
    searchPosition(position) {
      this.position = position;
      if (position.lat) {
        this.getLocationData(position.radio, position.lat, position.lng);
      }
    },
    getLocationData(radio, lat, lng) {
      this.searchByPosition = true;
      let content = null;
      this.isReady = false;
      const params = this.helpers.getRouteParameters(this.$route.path);
      getLocations(radio, lat, lng, params)
        .then(data => {
          if (Array.isArray(data)) {
            content = data;
          } else {
            content = [];
          }
        })
        .catch(() => {
          content = null;
        })
        .finally(() => {
          if (content) {
            this.resetFilters();
            this.searchReset = true;
            this.locationsCurrent = this.locationsSearchFormat(content);
            this.locationsCurrentByPosition = this.locationsSearchFormat(
              content
            );
          } else {
            this.resetFilters();
            this.searchReset = true;
            this.locationsCurrent = [];
          }
        });
    },
    resetFilters() {
      this.filters = {
        all: 0,
        branches: 0,
        atm: 0,
        depositExpress: 0,
        firstDeposit: 0
      };
    },
    locationsSearchFormat(content) {
      const localHelpers = this.helpers;
      let uContent = this.helpers.reduceDuplicates(content);

      return uContent.map(item => {
        return localHelpers.setLocationContentBySearch(item);
      });
    },
    locationSearchByText(text, locations) {
      return locations.filter(current => {
        const titleSearch = this.cleanAccent(current.title).toUpperCase();
        const addressSearch = this.cleanAccent(
          current.address_line
        ).toUpperCase();
        const addressGeneralSearch = this.cleanAccent(
          current.address
        ).toUpperCase();
        const cleanSearch = this.cleanAccent(text).toUpperCase();
        const title = titleSearch.indexOf(cleanSearch) !== -1;
        const address = addressSearch.indexOf(cleanSearch) !== -1;
        const addressGeneral = addressGeneralSearch.indexOf(cleanSearch) !== -1;
        return title === true || address === true || addressGeneral === true;
      });
    },
    searchItems(text) {
      this.locationsSearchText = text;
      if (this.filtersActive) {
        this.setFilters(this.filters);
      } else {
        let locations = this.locationsData;
        if (this.position != null) {
          locations = this.locationsCurrentByPosition;
        }
        if (text) {
          this.locationsSearchText = text;
          this.locationsCurrent = this.locationSearchByText(text, locations);
        }
      }
    },
    setFilters(filter) {
      let locations = this.locationsData;
      if (this.position != null) {
        locations = this.locationsCurrentByPosition;
      }
      if (this.locationsSearchText != null) {
        locations = this.locationSearchByText(
          this.locationsSearchText,
          locations
        );
      }

      this.searchReset = false;

      Object.keys(filter).forEach(key => {
        if (filter[key] == 1) {
          this.filtersActive = true;
          if (key == "all") {
            this.searchReset = true;
            this.locationsSearchText = null;
            this.locationsCurrent = locations;
            this.filtersActive = false;
            // this.position = null;
            // this.locationsCurrentByPosition = null;
          }
          if (key == "branches") {
            this.locationsCurrent = locations.filter(current => {
              return current.is_atm === false;
            });
          }
          if (key == "atm") {
            this.locationsCurrent = locations.filter(current => {
              return current.is_atm === true;
            });
          }
          if (key == "depositExpress") {
            this.locationsCurrent = locations.filter(current => {
              return current.express_deposit === true;
            });
          }
          if (key == "firstDeposit") {
            this.locationsCurrent = locations.filter(current => {
              return current.first_deposit === true;
            });
          }
        }
      });
    },

    resetStates() {
      const locatorCache = this.$cookies.get("locator_cache");
      if (locatorCache != "1") {
        this.$store.dispatch("locations/resetLocations");
      } else {
        // console.log("NO RESET CACHE");
      }
    },
    async setLocations() {
      window.location.hash = "";
      const cacheTime = process.env.VUE_APP_HDRUPAL_VUEX_CACHE;
      this.$cookies.set("locator_cache", 1, cacheTime);
      const params = this.helpers.getRouteParameters(this.$route.path);

      if (params.lang === "es") {
        document.title = "Localizador - 1FirstBank";
      }

      this.locationsBase = await this["locations/getLocations"]({
        lang: params.lang,
        region: params.region
      });

      if (this.locationsBase == null) {
        await this.$store
          .dispatch("locations/setAllLocations", params)
          .finally(() => {
            this.locationsBase = this["locations/getLocations"]({
              lang: params.lang,
              region: params.region
            });
          });
      }

      this.setLocationsData(params);
    },
    currentItemExist(hash) {
      const currentItem = this.locationsCurrent.filter(item => {
        return parseInt(item.id) === parseInt(hash);
      });

      if (Array.isArray(currentItem)) {
        this.locationsCurrentItem = currentItem[0];
        return true;
      }

      this.locationsCurrentItem = null;
      return false;
    },
    setLocationsData(params) {
      const localThis = this.helpers;
      const base = this.locationsBase;
      if (base) {
        if(params.region == "PR"){
          this.locationsData = base.map(item => {
            return localThis.setLocationContentBySearch(item);
          });
        } else {
          this.locationsData = base.map(item => {
            return localThis.setLocationContent(item);
          });
        }

        this.locationsCurrent = this.locationsData;
      }
    },
    validateHash() {
      const currentHash = location.hash;
      if (currentHash) {
        this.hash = currentHash.replace("#location-", "");
        if (this.hash > 0) {
          return true;
        }
      }

      this.hash = false;
      return false;
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.validateHash();
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss"></style>
