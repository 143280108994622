<template>
    <b-container class="container-4 c-locator-counter center-vertical" fluid="flex">
        <div class="container-lg">
            {{ $t('locator.show') }} <span>{{ counter }}</span> {{ $t('locator.results') }}
        </div>
    </b-container>
</template>

<script>
    export default {
        name: "LocatorCounter",
        props: {
            counter: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>