export function setLocationContentBySearch(content) {
  return {
    id: content.id,
    title: content.title,
    address: content.address,
    address_line: content.address_line,
    phone: content.phone,
    fax_number: content.fax_number,
    services: this.setServicesBySearch(content.services),
    map_image: content.map_image,
    map_link: content.map_link,
    monday_friday: content.monday_friday,
    saturday: content.saturday,
    sunday: content.sunday,
    message_alert: content.message_alert,
    branch: content.branch,
    is_atm: content.is_atm == "1" ? true : false,
    first_deposit: content.first_deposit == "1" ? true : false,
    express_deposit: content.express_deposit == "1" ? true : false
  };
}

export function reduceDuplicates(content) {
  const uniqueIds = new Set();

  const unique = content.filter(element => {
    const isDuplicate = uniqueIds.has(element.id);

    uniqueIds.add(element.id);

    if (!isDuplicate) {
      return true;
    }

    return false;
  });

  return unique;
}

export default {
  getRouteParameters(path) {
    const hash = window.location.hash;
    let region = "PR";
    if(hash){
      region = hash.replace('#', '');
    }
    if (path.includes("es/cookies")) {
      return {
        lang: "es",
        region: region
      };
    }
    if (path.includes("en/cookies")) {

      return {
        lang: "en",
        region: region
      };
    }

    const pathNew =
      "/" +
      path
        .split("/")
        .filter(n => n)
        .join("/");

    const regions = [
      {
        path: "/pr/es",
        lang: "es",
        region: "PR",
        offset: 0
      },
      {
        path: "/pr/en",
        lang: "en",
        region: "PR",
        offset: 0
      },
      {
        path: "/fl/en",
        lang: "en",
        region: "FL",
        offset: 0
      },
      {
        path: "/vi/en",
        lang: "en",
        region: "VI",
        offset: 0
      }
    ];

    const data = regions.filter(region => {
      return region.path === pathNew;
    });
    if (data) {
      return data[0];
    }

    return {
      lang: null,
      region: null
    };
  },

  setServicesBySearch(item) {
    if (!item) {
      return null;
    }
    const itemArray = item.split(",");
    let servicesObject = [];
    itemArray.forEach(nameItem => {
      const current = {
        name: nameItem
      };
      servicesObject.push(current);
    });

    return servicesObject;
  },
  updateBaseSiteLink(region, lang) {
    let link = "https://www.1firstbank.com/pr/es";
    if (region.toLowerCase() === "pr") {
      if (lang === "en") {
        link = "https://www.1firstbank.com/pr/en";
      }
    }
    if (region.toLowerCase() === "fl") {
      link = "https://www.1firstbank.com/fl/en";
    }

    if (region.toLowerCase() === "vi") {
      link = "https://www.1firstbank.com/vi/en";
    }

    return link;
  },
  reduceDuplicates(content) {
    const uniqueIds = new Set();

    const unique = content.filter(element => {
      const isDuplicate = uniqueIds.has(element.id);

      uniqueIds.add(element.id);

      if (!isDuplicate) {
        return true;
      }

      return false;
    });

    return unique;
  },
  setLocationContentBySearch(content) {
    return {
      id: content.id,
      title: content.title,
      address: content.address,
      address_line: content.address_line,
      phone: content.phone,
      fax_number: content.fax_number,
      services: this.setServicesBySearch(content.services),
      map_image: content.map_image,
      map_link: content.map_link,
      monday_friday: content.monday_friday,
      saturday: content.saturday,
      sunday: content.sunday,
      message_alert: content.message_alert,
      branch: content.branch,
      is_atm: content.is_atm == "1" ? true : false,
      first_deposit: content.first_deposit == "1" ? true : false,
      express_deposit: content.express_deposit == "1" ? true : false
    };
  },
  setMenusContent(content) {
    return content;
  },
  setLocationContent(content) {
    return {
      id: content.drupal_internal__nid,
      title: content.title,
      address: this.setTextAreaField(content.address),
      address_line: content.address_line,
      phone: content.phone_number,
      fax_number: content.fax_number,
      services: content.field_services,
      map_image: content.map_image,
      map_link: content.url,
      monday_friday: content.monday_friday,
      monday_friday_extended: content.field_friday_extended,
      monday_friday_autobank: content.field_monday_friday_autobank,
      monday_friday_extended_autobank: content.field_friday_extended_autobank,
      saturday_autobank: content.field_saturday_autobank,
      saturday: content.saturday,
      sunday: content.field_sunday,
      message_alert: this.setTextAreaField(content.field_message_alert),
      branch: this.setBranch(content.field_website),
      is_atm: content.is_atm,
      first_deposit: content.field_firstdeposit,
      express_deposit: content.field_express_deposit
    };
  },
  setBranch(item) {
    if (!item) {
      return null;
    }

    return {
      id: item.drupal_internal__tid,
      name: item.name
    };
  },
  setTextAreaField(field) {
    if (!field) {
      return null;
    }

    return field.value;
  },
  haveSubMenu(html, items) {
    if (html) {
      return true;
    }
    if (items.length > 1) {
      return true;
    }

    return false;
  },
  getFullImagePath(uri) {
    if (uri) {
      return `${process.env.VUE_APP_HDRUPAL_BASE_URL}${uri}`;
    }

    return null;
  },
  getMenuUrl(uri, lang) {
    if (uri === "route:<nolink>") {
      return false;
    }
    let cleanURI = uri.replace("internal:/es/", "/es/");
    if (uri.includes("internal:/es/")) {
      cleanURI = uri.replace("internal:/es/", "/es/");
      return cleanURI;
    }
    if (uri.includes("internal:/en/")) {
      cleanURI = uri.replace("internal:/en/", "/en/");
      return cleanURI;
    }
    if (uri.includes("internal:#")) {
      cleanURI = uri.replace("internal:#", `#`);
      return cleanURI;
    }
    if (uri.includes("internal:/")) {
      cleanURI = uri.replace("internal:/", `/${lang}/`);
      return cleanURI;
    }

    return uri;
  },
  renderSpanString(name) {
    const data = name.split("--");
    if (data.length > 1) {
      return data[0] + "<strong>" + data[1] + "</strong>";
    }

    return name;
  }
};
