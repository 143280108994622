<template>
  <div>
    <div class="nav-bar-container bg-base-green">
      <b-container fluid="lg" class="">
        <b-navbar
          type="dark"
          variant="primary"
          class="d-none d-lg-block bg-base-green pl-0 pr-0 overflow-auto"
          id="top-menu"
          role="top-menu"
        >
          <b-navbar-nav
            class="navbar-wpc float-left overflow-hidden"
            v-if="menuExist('top_left')"
          >
            <li v-for="item in getMenu('top_left')" :key="item.id">
              <a
                :href="
                  helpers.getMenuUrl(item.field_pg_menu_link.uri, language)
                "
                v-if="helpers.getMenuUrl(item.field_pg_menu_link.uri, language)"
                >{{ item.field_pg_menu_link.title }}</a
              >
              <span
                v-else
                v-html="helpers.renderSpanString(item.field_pg_menu_link.title)"
              ></span>
            </li>
          </b-navbar-nav>
          <b-navbar-nav
            class="navbar-wpc float-right overflow-hidden"
            v-if="menuExist('top_right')"
          >
            <li v-for="item in getMenu('top_right')" :key="item.id">
              <a
                :href="
                  helpers.getMenuUrl(item.field_pg_menu_link.uri, language)
                "
                v-if="helpers.getMenuUrl(item.field_pg_menu_link.uri, language)"
                >{{ item.field_pg_menu_link.title }}</a
              >
              <span
                v-else
                v-html="helpers.renderSpanString(item.field_pg_menu_link.title)"
              ></span>
            </li>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
    <div class="nav-bar-container bg-white">
      <b-container fluid="lg" class="">
        <b-navbar
          type="light"
          variant="faded"
          class="bg-white pl-0 pr-0 pt-0 pb-0"
          id="main-menu"
        >
          <div class="nav-brand-box">
            <b-navbar-brand :href="headerLink">
              <img
                src="../assets/images/fb-logo.svg"
                class="d-inline-block align-top d-lg-block d-md-none desktop-logo"
                alt="FirstBank Logo"
                title="Inicio"
              />
              <img
                src="../assets/images/logo_mobile.png"
                class="d-inline-block align-top d-lg-none mobile-logo"
                alt="FirstBank Logo"
                title="Inicio"
              />
            </b-navbar-brand>
            <div class="aside__selectors hide-desktop">
              <sign-in v-if="menuExist('connect')" :menu="getMenu('connect')" />
            </div>
          </div>

          <main-menu
            v-if="menuExist('main_menu')"
            :menu="getMenu('main_menu')"
          />

          <div
            class="float-right navbar-right ml-auto language-nav hide-mobile"
          >
            <!--            <LanguageSwitch />-->
            <language-selector />
          </div>
          <div class="aside__selectors hide-mobile">
            <sign-in v-if="menuExist('connect')" :menu="getMenu('connect')" />
          </div>
          <b-navbar-nav
            class="float-right navbar-right ml-auto language-nav hide-desktop"
          >
            <!--            <LanguageSwitch />-->
            <language-selector />
          </b-navbar-nav>
          <button
            type="button"
            id="hamburg"
            class="navbar-toggle mobile--menu hamburger hamburger--collapse hidden-xl-none"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            @click.prevent="showMenuCheck"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar icon-bar-1"></span>
            <span class="icon-bar icon-bar-2"></span>
            <span class="icon-bar icon-bar-3"></span>
          </button>
          <div class="mobile-menu-data">
            <main-menu-mobile
              v-if="menuExist('main_menu')"
              :menu="getMenu('main_menu')"
              :block="getBlock('main_menu_block')"
            />
          </div>
        </b-navbar>
      </b-container>
    </div>
  </div>
</template>

<script>
// import LanguageSwitch from "./LanguageSwitch/Index";
import MainMenu from "./Menu/MainMenu";
import SignIn from "./Menu/SignIn";
import MainMenuMobile from "./Menu/MainMenuMobile";
import { mapGetters } from "vuex";
import LanguageSelector from "./sections/languageSelector";
export default {
  name: "SiteHeader",
  components: { LanguageSelector, MainMenuMobile, SignIn, MainMenu },
  data: function() {
    return {
      language: this.$i18n.locale,
      headerLink: "/es/pr",
      content: null,
      showMenu: false,
      menusBase: null,
      blocksBase: null,
      menusData: null,
      helpers: this.$helperList,
      blockList: {
        main_menu_block: {
          id: "main-menu-mb",
          data: null
        }
      },
      menuList: {
        top_right: {
          id: "header-right",
          data: null
        },
        top_left: {
          id: "header-left",
          data: null
        },
        main_menu: {
          id: "main-menu",
          data: null
        },
        connect: {
          id: "connect",
          data: null
        }
      },
      menuData: {
        top_right: null,
        top_left: null,
        main_menu: null
      }
    };
  },
  computed: {
    ...mapGetters(["menus/getMenus", "blocks/getBlocks"])
  },
  async created() {
    this.resetMenuStates();
    this.resetBlockStates();
    this.updateLogoLink();
    const localThis = this;
    setTimeout(function() {
      localThis.setMenus();
      localThis.setBlocks();
    }, 1000);
  },
  methods: {
    async setBlocks() {
      const cacheTime = process.env.VUE_APP_HDRUPAL_VUEX_BLOCK_CACHE;
      this.$cookies.set("block_cache", 1, cacheTime);
      const params = this.helpers.getRouteParameters(this.$route.path);
      if (params) {
        const blockParams = {
          lang: params.lang,
          region: params.region,
          template: null
        };
        this.blocksBase = await this["blocks/getBlocks"](blockParams);

        if (this.blocksBase == null) {
          await this.$store
            .dispatch("blocks/updateBlock", blockParams)
            .finally(() => {
              this.blocksBase = this["blocks/getBlocks"](blockParams);
            });
        }

        this.setBlocksData();
      }
    },
    async setMenus() {
      const cacheTime = process.env.VUE_APP_HDRUPAL_VUEX_MENU_CACHE;
      this.$cookies.set("menu_cache", 1, cacheTime);
      const params = this.helpers.getRouteParameters(this.$route.path);

      if (params) {
        const menuParams = {
          lang: params.lang,
          region: params.region,
          template: null
        };
        this.menusBase = await this["menus/getMenus"](menuParams);
        if (this.menusBase == null) {
          await this.$store
            .dispatch("menus/updateMenu", menuParams)
            .finally(() => {
              this.menusBase = this["menus/getMenus"](menuParams);
            });
        }

        this.setMenusData();
      }
    },
    menuExist(id) {
      if (this.menuList[id]) {
        if (this.menuList[id].data) {
          if (this.menuList[id].data.length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    blockExist(id) {
      if (this.blockList[id]) {
        if (this.blockList[id].data) {
          if (this.blockList[id].data.length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    getBlock(id) {
      if (!this.blockExist(id)) {
        return {};
      }

      return this.blockList[id].data[0];
    },
    getMenu(id) {
      if (!this.menuExist(id)) {
        return [];
      }

      return this.menuList[id].data[0].items;
    },
    setBlocksData() {
      const base = this.blocksBase;
      const blockList = this.blockList;
      if (base) {
        Object.keys(blockList).forEach(function(key) {
          blockList[key].data = base.filter(blockItem => {
            return blockItem.field_custom_template === blockList[key].id;
          });
        });
      }
    },
    setMenusData() {
      const base = this.menusBase;
      const menuList = this.menuList;
      if (base) {
        Object.keys(menuList).forEach(function(key) {
          menuList[key].data = base.filter(menuItem => {
            return menuItem.field_custom_template === menuList[key].id;
          });
        });
      }
    },
    resetMenuStates() {
      const menuCache = this.$cookies.get("menu_cache");
      if (menuCache != "1") {
        this.$store.dispatch("menus/resetMenus");
      }
    },
    resetBlockStates() {
      const blockCache = this.$cookies.get("block_cache");
      if (blockCache != "1") {
        this.$store.dispatch("blocks/resetBlocks");
      }
    },
    showMenuCheck() {
      this.showMenu = !this.showMenu;
      const classBody = "fix-menu-mobile";
      if (this.showMenu) {
        document.body.classList.add(classBody);
      } else {
        document.body.classList.remove(classBody);
      }
    },
    updateLogoLink() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      if (params) {
        this.headerLink = this.helpers.updateBaseSiteLink(
          params.region,
          params.lang
        );
      } else {
        this.headerLink = "/es";
        if (this.language === "en") {
          this.headerLink = "/en";
        }
      }
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.updateLogoLink();
    }
  },
  watch: {
    $route() {
      this.updateLanguage();
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.site-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-brand-box {
  @media (max-width: 1024px) {
    width: 100%;
    .navbar-brand {
      float: left;
      padding-right: 15px;
      border-right: 1px solid #f0f0f0;
      margin-right: 15px;
    }
    .aside__selectors {
      margin-top: 9px;
      float: left;
      width: auto;
    }
  }
}
.desktop-logo {
  display: none !important;
  @media (min-width: 1024px) {
    display: block !important;
  }
}
.mobile-menu-data {
  @media (min-width: 1025px) {
    display: none;
  }
}
.mobile-logo {
  display: none !important;
  @media (max-width: 1024px) {
    display: block !important;
  }
}
.fbfBanner h1 {
  text-align: center;
  font-size: 45px;
  line-height: 1.13;
  letter-spacing: -0.84px;
  font-weight: bold;
  width: 100%;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    text-align: left;
  }
}
.nav-link {
  border-right: 1px solid #fff;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Helvetica", Arial, sans-serif !important;
}
</style>
