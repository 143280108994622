import * as contentApi from "@/api/contentApi";

const menus = {
  namespaced: true,
  state: {
    flEn: null,
    viEn: null,
    prEn: null,
    prEs: null
  },
  mutations: {
    resetAllMenus(state, payload) {
      state.flEn = payload;
      state.prEs = payload;
      state.prEn = payload;
      state.viEn = payload;
    },
    setMenuFlEn(state, payload) {
      state.flEn = payload;
    },
    setMenuViEn(state, payload) {
      state.viEn = payload;
    },
    setMenuPrEn(state, payload) {
      state.prEn = payload;
    },
    setMenuPrEs(state, payload) {
      state.prEs = payload;
    }
  },
  actions: {
    resetMenus(context) {
      context.commit("resetAllMenus", null);
    },
    async updateMenu(context, payload) {
      const regionId = payload.region;
      const lang = payload.lang;
      await contentApi
        .getMenu(lang, regionId)
        .then(({ data }) => {
          if (regionId === "FL") {
            context.commit("setMenuFlEn", data.data);
          }
          if (regionId === "VI") {
            context.commit("setMenuViEn", data.data);
          }
          if (regionId === "PR") {
            if (lang === "es") {
              context.commit("setMenuPrEs", data.data);
            } else {
              // console.log(data);
              context.commit("setMenuPrEn", data.data);
            }
          }
        })
        .catch(() => {
          // console.log("ERROR ON CALL", error);
        });
    }
  },
  getters: {
    getMenuFlEn: state => {
      return state.flEn;
    },
    getMenuViEn: state => {
      return state.viEn;
    },
    getMenuPrEn: state => {
      return state.prEn;
    },
    getMenuPrEs: state => {
      return state.prEs;
    },
    getMenus: state => region => {
      const regionId = region.region;
      const lang = region.lang;
      const template = region.template;
      let data = null;
      if (regionId === "FL") {
        data = state.flEn;
      }
      if (regionId === "VI") {
        data = state.viEn;
      }
      if (regionId === "PR") {
        if (lang === "es") {
          data = state.prEs;
        } else {
          data = state.prEn;
        }
      }
      if (data) {
        if (template) {
          return data.filter(dataItem => {
            return dataItem.field_custom_template == template;
          });
        }

        return data;
      }

      return null;
    }
  }
};

export default menus;
