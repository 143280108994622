<template>
    <b-container class="container-back c-locator-back center-vertical" fluid="flex">
        <div class="container-lg">
            <a href="#" @click.prevent="backBase"><img src="../../assets/images/long-arrow-left.svg" /> <span>{{ $t('back') }}</span></a>
        </div>
    </b-container>
</template>

<script>
    export default {
        name: "LocatorItemCompleteBack",
        methods: {
            backBase(){
                const name = this.$route.name;
                this.$router.push({ name: name, hash: ''})
            },
        },
    }
</script>

<style scoped>

</style>