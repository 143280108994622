<template>
    <div class="n-row block-on-767">
        <div class="ccol ccol-1">
            <img :src="helpers.getFullImagePath(block.field_image.uri.url)" alt="">
        </div>
        <div class="ccol ccol-2" v-html="block.body.value">
        </div>
    </div>
</template>

<script>
    export default {
        name: "copyRightFooter",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        data(){
            return {
                helpers: this.$helperList,
            }
        },
    }
</script>

<style scoped>

</style>