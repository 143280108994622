<template>
  <b-container
    class="container-2 c-locator-search center-vertical"
    fluid="flex"
  >
    <div class="container-lg">
      <div class="search-lat-long">
        <a href="#" @click="searchByPosition()" :aria-label="$t('locator.geo_label')">{{
          $t("locator.geo_label")
        }}</a>
      </div>
      <div class="search-form">
        <form @submit.prevent="onSubmit" class="form" action>
          <b-form-row>
            <b-form-group>
              <input
                type="text"
                class="search-input"
                :placeholder="$t('locator.search_input')"
                required
                v-model="form.name"
              />
              <button
                type="submit"
                class="btn"
                :class="{ submit: submitting }"
                :disabled="submitting === true"
                aria-label="Search"
              >
                <img :src="btn_icon" />
              </button>
            </b-form-group>
            <b-form-group>

<!--              <div class="locations" v-if="position">-->
<!--                <label>Lat: {{ position.lat}} </label>-->
<!--                <label>Lng: {{ position.lng}} </label>-->
<!--                <label>radio: {{ position.radio}} </label>-->
<!--              </div>-->
            </b-form-group>
          </b-form-row>
        </form>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "LocatorSearch",
  props: {
    reset: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: ""
      },
      btn_icon: require("@/assets/images/search.svg"),
      submitting: false,
      position: null,
      helpers: this.$helperList
    };
  },
  watch: {
    reset: function() {
      if (this.reset) {
        // this.form.name = "";
      }
    },
    position: function() {
      this.$emit("position", this.position);
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.searchData();
    },
    searchData() {
      const text = this.form.name;
      const lowText = text.toLowerCase();
      const newText = lowText.replace("mayaguez", "mayagüez");
      this.submitting = false;

      this.$emit("search-items", newText);
    },
    searchByPosition() {
      this.$getLocation();
      const radio = 10;
      const options = {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      };

      this.$getLocation(options).then(coordinates => {
        const lat = coordinates.lat ? coordinates.lat : null;
        const lng = coordinates.lng ? coordinates.lng : null;
        // console.log(coordinates);
        // const lat = '18.386388733006694';
        // const lng = '-66.18608858679036';
        // console.log(coordinates);
        this.position = {
          lat: lat,
          lng: lng,
          radio: radio
        };
        // console.log(coordinates, lat, lng, radio);
        //
      });
      // console.log("SEARCH BY LOCATIONS");
    }
  }
};
</script>

<style scoped></style>
