import { Trans } from "@/plugins/translation";
import Home from "@/views/Home";
import Reset from "@/views/Reset";
import cookiesPage from "../views/cookies.vue";

export default [
  {
    path: "/es/cookies",
    name: "cookiesEs",
    component: cookiesPage,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/en/cookies",
    name: "cookiesEn",
    component: cookiesPage,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/pr/en",
    name: "HomePrEn",
    component: Home,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/pr/es",
    name: "HomePrEs",
    component: Home,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/fl/en",
    name: "HomeFlEn",
    component: Home,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/vi/en",
    name: "HomeViEn",
    component: Home,
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: "/reset",
    name: "RESET",
    component: Reset
  },
  {
    // Redirect user to supported lang version.
    path: "*",
    // eslint-disable-next-line no-unused-vars
    redirect(to) {
      return Trans.getUserSupportedLang();
    }
  }
];
