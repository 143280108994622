<template>
  <b-container
    class="container-1 c-locator-header center-vertical"
    fluid="flex"
  >
    <div class="container-lg">
      <h1>{{ $t("locator.title") }}</h1>
<!--      <p v-show="isPR()">{{ $t("locator.title_description_0") }}</p>-->
<!--      <br v-show="isPR()" />-->
      <p>{{ $t("locator.title_description") }}</p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "locatorHeader",
  data() {
    return {
      language: this.$i18n.locale,
      url: this.$router.currentRoute.fullPath
    };
  },
  watch: {
    $route() {
      this.url = this.$router.currentRoute.fullPath;
      this.language = this.$i18n.locale;
    }
  },
  methods: {
    isPR() {
      if (this.url.includes("/pr/")) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style scoped></style>
