<template>
  <div id="app" :class="[updateBaseClass ? 'ready' : 'ready 2']">
    <SiteHeader />
    <router-view />
    <CookieModal />
      <SiteFooter />
    <div class="test-prod"></div>
  </div>
</template>
<script>
import SiteHeader from "@/components/Header";
import SiteFooter from "./components/Footer";
import CookieModal from "./components/cookieModal";
export default {
  components: {
    CookieModal,
    SiteFooter,
    SiteHeader
  },
  methods: {
    updateBaseClass(){
      const uri = this.$route.fullPath;
      if(uri.search('fl') > 0){
        document.body.classList.add("fl-page");
      }
      if(uri.search('vi') > 0){
        document.body.classList.add("vi-page");
      }
    }
  },
  watch: {
    $route() {
      this.updateBaseClass()
    }
  },
};
</script>
<style scoped lang="scss">

</style>