import * as contentApi from "@/api/contentApi";

const blocks = {
    namespaced: true,
    state: {
        flEn: null,
        viEn: null,
        prEn: null,
        prEs: null,
    },
    mutations: {
        resetAllBlocks(state, payload){
          state.flEn = payload;
          state.prEs = payload;
          state.prEn = payload;
          state.viEn = payload;
        },
        setBlockFlEn(state, payload) {
            state.flEn = payload;
        },
        setBlockViEn(state, payload) {
            state.viEn = payload;
        },
        setBlockPrEn(state, payload) {
            state.prEn = payload;
        },
        setBlockPrEs(state, payload) {
            state.prEs = payload;
        },
    },
    actions: {
        resetBlocks(context){
            context.commit('resetAllBlocks', null);
        },
        async updateBlock(context, payload) {
            // let template = null;
            // if(payload.template){
            //     template = payload.template;
            // }
            const regionId = payload.region;
            const lang = payload.lang;
            await contentApi.getBlock(lang, regionId)
                .then(({data}) => {
                    // console.log("DATA BLOCKS", data);
                    if(regionId === "FL"){
                        context.commit('setBlockFlEn', data.data)
                    }
                    if(regionId === "VI"){
                        context.commit('setBlockViEn', data.data)
                    }
                    if(regionId === "PR"){
                        if(lang === "es"){
                            context.commit("setBlockPrEs", data.data);
                        } else{
                            context.commit("setBlockPrEn", data.data);
                        }
                    }
                })
                .catch(() => {
                    // console.log("ERROR ON CALL", error);
                });

        }
    },
    getters: {
        getBlockFlEn: (state) => {
            return state.flEn;
        },
        getBlockViEn: (state) => {
            return state.viEn;
        },
        getBlockPrEn: (state) => {
            return state.prEn;
        },
        getBlockPrEs: (state) => {
            return state.prEs;
        },
        getBlocks: (state) => (region) => {
            const regionId = region.region;
            const lang = region.lang;
            const template = region.template;
            let data = null;
            if (regionId === "FL") {
                data = state.flEn;
            }
            if (regionId === "VI") {
                data = state.viEn;
            }
            if (regionId === "PR") {
                if (lang === "es") {
                    data = state.prEs;
                } else {
                    data = state.prEn;
                }
            }
            if(data){
                if(template){
                    return data.filter(dataItem => {
                        return dataItem.field_custom_template == template;
                    });
                }

                return data;
            }





            return null;
        },
    },
}

export default blocks;