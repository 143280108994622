<template>
    <b-container class="container-4 c-locator-counter center-vertical" fluid="flex">
        <div class="container-lg" v-if="resetContent()">
            <h1>RESET CONTENT</h1>
            <ul>
                <li>
                    <router-link to="/pr/es">PR Es</router-link>
                </li>
            </ul>
        </div>
    </b-container>
</template>

<script>
    export default {
        name: "Reset",
        data(){
            return {
             reset: false,
            }
        },
        methods: {
            resetContent(){
                this.$cookies.set('locator_cache', 5, '1m');
                this.$cookies.set('block_cache', 5, '1m');
                this.$cookies.set('menu_cache', 5, '1m');
                this.reset = true;
                return this.reset;
            },
        },
    }
</script>

<style scoped>

</style>