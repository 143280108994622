var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-container-c-wp",attrs:{"role":"main"}},[_c('cookie-header'),_c('div',{staticClass:"main-container-c container-xl"},[(_vm.validateCookies())?_c('div',{staticClass:"pl-0 pr-0 container-lg"},[_c('div',{staticClass:"header-content container-lg"},[_c('section',{staticClass:"section-item section-1"},[_c('h2',[_vm._v(_vm._s(_vm.printLanguage(_vm.general.title)))]),_c('div',{staticClass:"section-content"},[_c('p',[_vm._v(_vm._s(_vm.printLanguage(_vm.general.p)))]),_c('p',[_vm._v(_vm._s(_vm.printLanguage(_vm.general.p1)))]),_c('p',[_vm._v(_vm._s(_vm.printLanguage(_vm.general.p2)))])])]),_vm._l((_vm.cookies),function(item,index){return _c('section',{key:item.id,staticClass:"section-item section-sub",class:[
            index === 0 ? 'first' : '',
            index + 1 === _vm.cookies.length ? 'last' : '',
            ("section-item-" + (item.id))
          ]},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.printLanguage(item.title))}}),_c('div',{staticClass:"section-content",domProps:{"innerHTML":_vm._s(_vm.printLanguage(item.description))}}),_c('div',{staticClass:"check-select"},[_c('div',{staticClass:"switch-on-off-wrapper",class:[
                item.name,
                item.value ? 'switch-on-wrapper' : 'switch-off-wrapper'
              ],on:{"click":function($event){$event.preventDefault();return _vm.assignCookieValue(item.value, index)}}},[_c('div',{staticClass:"switch-circle",class:[item.name, item.value ? 'switch-on' : 'switch-off']},[(item.value)?_c('p',{staticClass:"switch-on-off-text"},[_vm._v(" "+_vm._s(_vm.printLanguage(_vm.yes))+" ")]):_vm._e(),(!item.value)?_c('p',{staticClass:"switch-on-off-text"},[_vm._v("No")]):_vm._e()])])])])})],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }