<template>
  <div v-if="sectionIsReady()">
    <!-- Footer -->
    <b-container class="footer-wrapper" fluid="flex">
      <div class="btt-mobile hide-desktop">
        <table class="linea-de-colores">
          <tbody>
            <tr>
              <td class="bg-color-light-green">&nbsp;</td>
              <td class="bg-color-dark-pink">&nbsp;</td>
              <td class="bg-color-yellow">&nbsp;</td>
              <td class="bg-color-dark-red">&nbsp;</td>
              <td class="bg-color-purple">&nbsp;</td>
              <td class="bg-color-light-blue">&nbsp;</td>
              <td class="bg-color-dark-green">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <div class="back-to-top-mobile-wrapper">
          <a
            href="#top"
            class="back-to-top"
            @click="backTop"
            aria-label="back to top"
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <img
                      class="mobile hide-desktop"
                      src="../assets/images/arrow-up.svg"
                      alt="Back to top"
                      width="15"
                      height="15"
                    />
                  </td>
                  <td><p class="hide-desktop">BACK TO TOP</p></td>
                </tr>
              </tbody>
            </table>
          </a>
        </div>
      </div>
      <div class="btt hide-mobile">
        <div class="site__container container-xl">
          <a href="#top" class="back-to-top" aria-label="back to top"> </a>
        </div>
      </div>
      <div class="footer footer-bottom container-lg">
        <div class="n-row block-on-767" v-if="menuExist('menu')">
          <div class="ccol hide-on-767 hide-mobile">
            <a :href="headerLink" class="fsb-footer-log">
              <img
                src="../assets/images/Logo_1FirstBank.svg"
                alt="FirstBank"
                width="158"
                height="49"
              />
            </a>
          </div>
          <div
            class="ccol"
            v-for="(item, index) in getMenu('menu')"
            :key="item.id"
          >
            <div
              class="fsb-widget fsb-list"
              :class="[isSubMenuActive(item.id) ? 'show' : '']"
            >
              <div class="fsb-title" @click="openMenu(item.id)">
                {{ item.field_pg_menu_link.title }}
              </div>
              <div
                class="fsb-content"
                :class="[index > 2 ? 'apps-btns' : '']"
                v-if="item.field_pg_menu_link_content"
                v-html="item.field_pg_menu_link_content.value"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </b-container>

    <b-container
      class="footer-wrapper footer-container-2"
      fluid="flex"
    >
      <div class="container-lg fsb-bottom">
        <div class="fsb-phones-fsb-social-wrapper">
          <div class="site__container container-xl">
            <div class="fsb-phones">
              <div class="n-row align-item-center">
                <div class="ccol-lg-7">
                  <div class="n-row">
                    <phones-footer-block
                      v-if="blockExist('phones')"
                      :block="getBlock('phones')"
                    />
                  </div>
                </div>
                <div class="ccol-lg-5">
                  <social-footer-block
                    v-if="blockExist('social')"
                    :block="getBlock('social')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container
      class="footer-wrapper footer-container-3"
      fluid="flex"
    >
      <div class="container-lg fsb-bottom">
        <div class="fsb-notes">
          <div class="site__container container-xl">
            <copy-right-footer
              v-if="blockExist('legal')"
              :block="getBlock('legal')"
            />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PhonesFooterBlock from "./blocks/phonesFooterBlock";
import SocialFooterBlock from "./blocks/socialFooterBlock";
import CopyRightFooter from "./blocks/copyRightFooter";
export default {
  name: "SiteFooter",
  data: function() {
    return {
      language: this.$i18n.locale,
      params: null,
      headerLink: "/es",
      content: null,
      currentLang: null,
      showMenu: false,
      menusBase: null,
      blocksBase: null,
      menusData: null,
      subMenuActive: null,
      phonesBlock: null,
      regionInfo: { lang: "en", region: "pr", template: null },
      helpers: this.$helperList,
      blockList: {
        phones: {
          id: "phones",
          data: null
        },
        social: {
          id: "social-network-icons",
          data: null
        },
        legal: {
          id: "footer-legal",
          data: null
        }
      },
      menuList: {
        menu: {
          id: "menu-footer",
          data: null
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getMenus: "menus/getMenus",
      getBlocks: "blocks/getBlocks",
      getBlockPrEn: "blocks/getBlockPrEn",
      getBlockPrEs: "blocks/getBlockPrEs",
      getBlockFlEn: "blocks/getBlockFlEn",
      getBlockViEn: "blocks/getBlockViEn",
      getMenuPrEn: "menus/getMenuPrEn",
      getMenuPrEs: "menus/getMenuPrEs",
      getMenuFlEn: "menus/getMenuFlEn",
      getMenuViEn: "menus/getMenuViEn"
    })
  },
  // async created() {
  //   // this.params = this.helpers.getRouteParameters(this.$route.path);
  //   // const params = this.helpers.getRouteParameters(this.$route.path);
  //   // console.log("created", params);
  //   // if(params){
  //     // const localParams = {lang: params.lang, region: params.region, template: null };
  //     // const localParams = {lang: 'en', region: 'pr', template: null };
  //     // const menus = this.getMenus(localParams);
  //     // const blocks = this.getMenus(localParams);
  //     // console.log('******************************');
  //     // console.log(menus,blocks);
  //     // console.log('******************************');
  //   // }
  //   // console.log("TEST X1 -- MENUS", await this['menus/getMenus'](localParams));
  //   // console.log("TEST X1 --- BLOCKS", await this['blocks/getMenus'](localParams));
  //   // this.setMenus(this.params);
  //   // this.setBlocks(this.params);
  //   await this.setBlocks();
  // },
  components: { CopyRightFooter, SocialFooterBlock, PhonesFooterBlock },
  methods: {
    updateLogoLink() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      if (params) {
        this.headerLink = this.helpers.updateBaseSiteLink(
          params.region,
          params.lang
        );
      } else {
        this.headerLink = "/es";
        if (this.language === "en") {
          this.headerLink = "/en";
        }
      }
    },
    backTop(e) {
      window.scrollTo(0, 0);
      e.preventDefault();
    },
    sectionIsReady() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      if (!params) {
        return false;
      }
      this.updateLogoLink();
      if (this.currentLang === params.region) {
        return true;
      }
      if (params.region === "PR") {
        if (params.lang === "en") {
          if (this.getBlockPrEn && this.getMenuPrEn) {
            this.blocksBase = this.getBlockPrEn;
            this.menusBase = this.getMenuPrEn;
            this.setBlocksData();
            this.setMenusData();
            this.currentLang = params.region;
            return true;
          }
        } else {
          if (this.getBlockPrEs && this.getMenuPrEs) {
            this.blocksBase = this.getBlockPrEs;
            this.menusBase = this.getMenuPrEs;
            this.setBlocksData();
            this.setMenusData();
            this.currentLang = params.region;
            return true;
          }
        }
      }

      if (params.region === "VI") {
        if (this.getBlockViEn && this.getMenuViEn) {
          this.blocksBase = this.getBlockViEn;
          this.menusBase = this.getMenuViEn;
          this.setBlocksData();
          this.setMenusData();
          this.currentLang = params.region;
          return true;
        }
      }

      if (params.region === "FL") {
        if (this.getBlockFlEn && this.getMenuFlEn) {
          this.blocksBase = this.getBlockFlEn;
          this.menusBase = this.getMenuFlEn;
          this.setBlocksData();
          this.setMenusData();
          this.currentLang = params.region;
          return true;
        }
      }

      return false;
    },
    menuExist(id) {
      if (this.menuList[id]) {
        if (this.menuList[id].data) {
          if (this.menuList[id].data.length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    blockExist(id) {
      if (this.blockList[id]) {
        if (this.blockList[id].data) {
          if (this.blockList[id].data.length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    setBlockClass(id) {
      if (this.blockExist(id)) {
        this.phonesBlock = this.getBlock(id);
        return true;
      }

      return false;
    },
    getBlock(id) {
      if (!this.blockExist(id)) {
        return {};
      }

      return this.blockList[id].data[0];
    },
    getMenu(id) {
      if (!this.menuExist(id)) {
        return [];
      }

      return this.menuList[id].data[0].items;
    },
    setBlocksData() {
      const base = this.blocksBase;
      const blockList = this.blockList;
      if (base) {
        Object.keys(blockList).forEach(function(key) {
          blockList[key].data = base.filter(blockItem => {
            return blockItem.field_custom_template === blockList[key].id;
          });
        });
      }
      // console.log("BlockList", blockList);
    },
    setMenusData() {
      const base = this.menusBase;
      const menuList = this.menuList;
      Object.keys(menuList).forEach(function(key) {
        menuList[key].data = base.filter(menuItem => {
          return menuItem.field_custom_template === menuList[key].id;
        });
      });
    },
    openMenu(id) {
      // console.log("ID", id, this.subMenuActive);
      if (this.subMenuActive === id) {
        // console.log("IS NOT ACITVE SET TO 0");
        this.subMenuActive = 0;
      } else {
        // console.log("SET NEW ACTIVE", id);
        this.subMenuActive = id;
      }
    },
    isSubMenuActive(id) {
      // console.log(id, this.subMenuActive, this.subMenuActive === id);
      return this.subMenuActive === id;
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.updateLogoLink();
    }
  }
};
</script>
<style scoped lang="scss"></style>
