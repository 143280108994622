<template>
    <div class="mobile--btns-holder sign-in-menu">
        <b-navbar-nav class="float-left navbar-right mr-auto">
            <li class="sign-has-menu">
                <span class="btn conectate-button" @click="openModal">{{ menu[0].field_pg_menu_link.title }}</span>
                <ul class="sub-menu" :class="[
                    open ? 'active' : ''
                ]">

                    <div class="oss--card-id oss--card cf active-card" style="display: block;">
                        <div class="osc-item">
                            <a href="#" class="osc-active">{{ menu[0].field_pg_menu_link.title }}</a>
                            <button class="close-trigger" type="button" data-dismiss="alert" aria-label="Close" @click="openModal"><span class="close-trigger-span" aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div v-html="menu[0].field_pg_menu_link_content.value"></div>
                    </div>
                </ul>
            </li>
        </b-navbar-nav>
    </div>
</template>

<script>
    export default {
        name: "SignIn",
        props: {
            menu: {
                type: Array,
                required: true
            }
        },
        data(){
          return {
              open: false,
          }
        },
        methods: {
            openModal(){
                this.open = !this.open;
            }
        },
        mounted() {
            // document.getElementById('closeOscSpan').onclick = function(e){
            //     console.log("CLICK CLOSE", e);
            // };
        },
    }
</script>

<style lang="scss" scoped>
    table tr td{
        border: none;
    }
</style>