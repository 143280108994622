<template>
  <div
    v-if="noAcceptedUser()"
    id="cookie-alert-block"
    :class="[showModal ? '' : 'hide']"
  >
    <div class="cookie-container" v-if="params">
      <div class="content">
        <p class="content-text">
          {{ printLanguage(content) }}
          <a @click.prevent="setCookiesPage" class="set-cookie-link">{{
            printLanguage(custom)
          }}</a
          >.
        </p>
        <a href="#" class="btn btn-large" @click.prevent="cookieAccept">{{
          printLanguage(accept)
        }}</a>
      </div>
      <div class="close-w">
        <a class="close-icon" @click.prevent="setCookiesOff" aria-label="close"
          ><img src="@/assets/images/times-solid.svg" alt="Close" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cookieModal",
  data() {
    return {
      language: this.$i18n.locale,
      helpers: this.$helperList,
      params: null,
      cookies: {
        required: {
          id: 1,
          value: true
        },
        analytics: {
          id: 2,
          value: true
        },
        accept: true
      },
      showModal: true,
      content: {
        es:
          "Usamos cookies en nuestros sitios web. Para conocer más sobre la información que recopilamos, cómo la utilizamos y sus opciones, presione",
        en:
          "We use cookies on our websites. To learn more about the information we collect, how we use it, and your options, click"
      },
      custom: {
        es: "personalizar cookies",
        en: "customize cookies"
      },
      accept: {
        es: "Aceptar",
        en: "Accept"
      }
    };
  },
  watch: {
    $route() {
      const params = this.helpers.getRouteParameters(this.$route.path);
      this.params = params;
    }
  },
  methods: {
    printLanguage($data) {
      let params = this.params;
      if (!params) {
        params = this.helpers.getRouteParameters(this.$route.path);
      }

      if (params.lang === "en") {
        return $data.en;
      }

      return $data.es;
    },
    setCookiesPage() {
      let name = "cookiesEs";
      if (this.params) {
        if (this.params.lang === "en") {
          name = "cookiesEn";
        }
      }

      this.$router.push({ name: name, hash: '#'+ this.params.region });
    },
    cookieAccept() {
      this.$cookies.set("fb_location_c_status", this.cookies, {
        maxAge: 60 * 60 * 24 * 365
      });
      this.showModal = false;
    },
    setCookiesOff() {
      this.cookieAccept();
      // console.log('click')
      // this.showModal = false
    },
    noAcceptedUser() {
      if (this.showModal) {
        const status = this.$cookies.get("fb_location_c_status");
        if (status) {
          this.showModal = this.validateCookieStatus(status);
        } else {
          this.showModal = true;
        }
      }

      return this.showModal;
    },
    validateCookieStatus(status) {
      if (status.accept) {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$green: #038459;
$btn_bg: #ffffff;
$btn_text: #038459;
$font: Arial, sans-serif !important;
#cookie-alert-block {
  * {
    font-family: Arial, sans-serif !important;
  }
  background: $green;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 10px;
  z-index: 10;
  @media (max-width: 749px) {
    margin-top: 10px;
  }
  .cookie-container {
    width: 100%;
    overflow: hidden;
    display: block;
    position: relative;
    max-width: 1200px;
    @media (min-width: 750px) {
      margin-left: auto;
      margin-right: auto;
    }
    .set-cookie-link {
      text-decoration: underline !important;
    }
  }
  .content {
    max-width: 800px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    float: none;
    overflow: hidden;
    display: block;
    @media (min-width: 750px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      height: 100%;
    }
    @media (max-width: 1100px) {
      width: 80%;
    }
    @media (max-width: 749px) {
      width: 92%;
      padding-top: 10px;
    }

    p a,
    p {
      color: #fff;
      font-size: 13px;
      display: inline;
      text-align: left;
      margin-bottom: 0;
      a {
        text-decoration: underline;
        color: #fff;
        cursor: pointer;
      }
    }
    .content-text,
    .btn {
      float: left;
      width: calc(100% - 150px);
      padding-right: 70px;
      @media (max-width: 749px) {
        width: 100%;
      }
    }
    .content-text {
      @media (max-width: 749px) {
        margin-top: 20px;
        padding-right: 0;
      }
    }
    .btn {
      width: 150px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 1.5;
      padding: 0.6rem 0.75rem 0.375rem;
      -webkit-appearance: button;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background: $btn_bg;
      border-radius: 0;
      border: solid 2px $btn_bg;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.15px;
      color: $btn_text;
      text-transform: unset;
      text-decoration: none;
      &:hover {
        $radius: 100px;
        text-decoration: none;
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
        border-radius: $radius;
      }
      @media (max-width: 749px) {
        width: 110px;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
  .close-w {
    position: absolute;
    right: 1%;
    top: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    height: 100%;
    @media (max-width: 749px) {
      align-items: normal;
    }
    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      $radius: 15px;
      background: #224e40;
      border: 1px solid #224e40;
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      border-radius: $radius;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    img {
      color: #fff;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
