<template>
    <div class="site--navigation site--m-navigation px17">
        <b-navbar class="mobile--nav">
            <b-navbar-nav id="accordMenu">
                <li
                        v-for="item in menu"
                        :key="item.id"
                        :class="[
                            helpers.haveSubMenu(item.field_pg_menu_link_content_mb, []) ? 'has-childs' : '',
                            isSubMenuActive(item.id) ? 'show' : ''
                            ]"
                >
                    <a class="no-correct" :href="helpers.getMenuUrl(item.field_pg_menu_link.uri, language)" v-if="!helpers.haveSubMenu(item.field_pg_menu_link_content_mb, [])">{{ item.field_pg_menu_link.title }}</a>
                    <a v-else href="#" class="openMenu" @click="openMenu(item.id)">
                        {{ helpers.renderSpanString(item.field_pg_menu_link.title) }} <span class="openMenu-span" v-if="helpers.haveSubMenu(item.field_pg_menu_link_content_mb, [])"><i class="fa fa-plus" aria-hidden="true"></i></span></a>
                    <ul class="child-menu" style="display: none;"
                        v-if="helpers.haveSubMenu(item.field_pg_menu_link_content_mb, [])"
                        v-html="item.field_pg_menu_link_content_mb.value"
                    >
                    </ul>
                </li>
            </b-navbar-nav>
        </b-navbar>
        <div class="mobile--nav--sidebyside" v-if="block.body" v-html="block.body.value">
        </div>
        <div class="m__phone" v-if="block.field_header_title">
            <svg width="14" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#8db853" fill-rule="evenodd"><path d="M6.965 4.685c1.471 0 2.942.001 4.413-.005.086 0 .171-.07.257-.106-.05-.067-.092-.14-.15-.2-.433-.455-.881-.896-1.301-1.363-.188-.21-.136-.535.062-.754.261-.287.49-.347.77-.15.221.156.418.352.61.546.674.677 1.344 1.357 2.008 2.043.473.488.49.745.034 1.227-.761.804-1.544 1.587-2.321 2.376a1.68 1.68 0 01-.312.24c-.303.189-.537.14-.799-.157-.196-.222-.226-.559-.022-.782.417-.457.86-.891 1.287-1.34.054-.056.08-.142.118-.214-.08-.03-.16-.086-.24-.086-2.963.001-5.925.02-8.888.007C1.152 5.961.025 4.777.007 3.412-.005 2.51.002 1.609.004.708c0-.404.244-.693.59-.707a.682.682 0 01.698.687c.012.809-.006 1.618.012 2.427.006.268.034.557.137.8.231.544.687.771 1.261.771h4.263v-.001zM3.953 10.5V8.103c.001-.433.261-.761.606-.771.37-.011.668.331.669.783.003 1.505-.001 3.01.002 4.515.002.796.566 1.38 1.34 1.384 1.613.01 3.225.015 4.837.016.069 0 .138-.057.207-.088-.04-.073-.065-.16-.122-.217-.393-.396-.805-.774-1.186-1.18-.338-.361-.308-.745.03-1.043.202-.18.419-.202.638-.065.17.107.336.23.478.372.752.756 1.498 1.517 2.241 2.283.394.406.392.77 0 1.172-.751.77-1.504 1.54-2.261 2.304a2.23 2.23 0 01-.38.303c-.332.214-.567.164-.841-.162a.62.62 0 01.023-.811c.42-.441.858-.862 1.284-1.296.05-.05.11-.147.093-.194-.02-.054-.122-.106-.189-.106-1.47.006-2.94 0-4.41.039-.99.027-1.857-.196-2.498-1.016-.347-.443-.562-.941-.56-1.522.003-.768.001-1.535.001-2.303h-.002z"></path></g></svg>
            <span v-html="block.field_header_title.value"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainMenuMobile",
        props: {
            menu: {
                type: Array,
                required: true
            },
            block: {
                type: Object,
                required: true
            },
        },
        data(){
            return{
                helpers: this.$helperList,
                subMenuActive: 0,
                language: this.$i18n.locale,
            };
        },
        methods: {
            openMenu(id){
                if(this.subMenuActive === id){
                    this.subMenuActive = 0;
                } else {
                    this.subMenuActive = id;
                }
            },
            isSubMenuActive(id){
                return this.subMenuActive === id;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>