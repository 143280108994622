import * as contentApi from "@/api/contentApi";
import { getLocationPR } from "@/api/contentApi";
// import { reduceDuplicates, setLocationContentBySearch } from "@/utils/helpers";

const locations = {
  namespaced: true,
  state: {
    flEn: null,
    viEn: null,
    prEn: null,
    prEs: null,
    locationsCount: 0
  },
  mutations: {
    resetAllLocations(state, payload) {
      state.flEn = payload;
      state.prEs = payload;
      state.prEn = payload;
      state.viEn = payload;
      state.locationsCount = 0;
    },
    setLocationsCount(state, payload) {
      state.locationsCount = payload;
    },
    setLocationFlEn(state, payload) {
      if (state.flEn === null) {
        state.flEn = payload;
      } else {
        payload.forEach(item => {
          state.flEn.push(item);
        });
      }
    },
    setLocationViEn(state, payload) {
      if (state.viEn === null) {
        state.viEn = payload;
      } else {
        payload.forEach(item => {
          state.viEn.push(item);
        });
      }
    },
    setLocationPrEn(state, payload) {
      state.prEn = payload;
    },
    setLocationPrEs(state, payload) {
      state.prEs = payload;
    }
  },
  actions: {
    resetLocations(context) {
      context.commit("resetAllLocations", null);
    },
    async setAllLocations(context, payload) {
      // console.log("PAYLOAD", payload);
      if (payload.region == "PR") {
        await Promise.all([
          context.dispatch("updateLocationsPR", {
            lang: payload.lang
          })
        ]);
      } else {
        await Promise.all([
          context.dispatch("updateLocations", {
            lang: payload.lang,
            region: payload.region,
            offset: 0
          })
        ]);
      }
    },
    setServicesBySearch(item) {
      if (!item) {
        return null;
      }
      const itemArray = item.split(",");
      let servicesObject = [];
      console.log('itemArray', itemArray)
      itemArray.forEach(nameItem => {
        const current = {
          name: nameItem
        };
        servicesObject.push(current);
      });

      return servicesObject;
    },
    async updateLocationsPR(context, payload) {
      const lang = payload.lang;
      // console.log("REGION", payload);
      await getLocationPR(lang)
        .then(data => {
          if (Array.isArray(data)) {
            const uniqueIds = new Set();

            const dataFilter = data.filter(element => {
              const isDuplicate = uniqueIds.has(element.id);
              uniqueIds.add(element.id);
              if (!isDuplicate) {
                return true;
              }
              return false;
            });


            if (lang === "es") {
              context.commit("setLocationPrEs", dataFilter);
            } else {
              context.commit("setLocationPrEn", dataFilter);
            }
          } else {
            if (lang === "es") {
              context.commit("setLocationPrEs", []);
            } else {
              context.commit("setLocationPrEn", []);
            }
          }
        })
        .catch(() => {
          // console.log("ERROR ON CALL", error);
        });
    },
    async updateLocations(context, payload) {
      const regionId = payload.region;
      const lang = payload.lang;
      let offset = payload.offset;
      // console.log("REGION", payload);
      await contentApi
        .getLocationList(lang, regionId, offset)
        .then(({ data }) => {
          // console.log("DATA", data);
          if (regionId === "FL") {
            context.commit("setLocationFlEn", data.data);
          }
          if (regionId === "VI") {
            context.commit("setLocationViEn", data.data);
          }
          if (regionId === "PR") {
            if (lang === "es") {
              context.commit("setLocationPrEs", data.data);
            } else {
              context.commit("setLocationPrEn", data.data);
            }
          }
        })
        .catch(() => {
          // console.log("ERROR ON CALL", error);
        });
    }
  },
  getters: {
    getLocationsCount: state => {
      return state.locationsCount;
    },
    getLocations: state => region => {
      const regionId = region.region;
      const lang = region.lang;

      if (regionId === "FL") {
        return state.flEn;
      }
      if (regionId === "VI") {
        return state.viEn;
      }
      if (regionId === "PR") {
        if (lang === "es") {
          return state.prEs;
        } else {
          return state.prEn;
        }
      }

      return null;
    }
  }
};

export default locations;
