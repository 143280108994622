<template>
  <div class="header-cookie">
    <main id="main" role="main" class="container-xl pl-0 pr-0">
      <div class="pl-0 pr-0 container-lg">
        <div class="wp-title">
            <h1 v-html="printLanguage(title)"></h1>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'CookieHeader',
    data() {
        return {
            title: {
                es: 'Personaliza los cookies',
                en: 'Customize cookies',
            },
          language: this.$i18n.locale,
        }
    },
    methods: {
        printLanguage($data) {
            if (this.language === 'en') {
                return $data.en
            }

            return $data.es
        },
    },
}
</script>

<style scoped lang="scss">
.header-cookie {
  width: 100%;
  background: #038459;
  margin-bottom: 2.5rem;
  #main{
    min-height: 1.5rem;
  }
  .wp-title {
      /*max-width: 1400px;*/
      margin-left: auto;
      margin-right: auto;
      padding: 3% 0;
      @media(max-width: 1024px){
          /*padding-left: 20px;*/
          /*padding-right: 20px;*/
      }
  }
  h1 {
    font-size: 40px;
    color: #fff;
    width: 100%;
    padding: 0;
    margin: 1rem 0;
    @media (max-width: 1024px) {
          font-size: 32px;
    }
    @media (max-width: 750px) {
      font-size: 28px;
    }
  }
}
</style>
